/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormRenderProps, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  CuidadoCompartilhadoQueryFilterEnum,
  StatusCuidadoCompartilhadoEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import { filterCheckboxLabelRender } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'
import {
  ListaCuidadoCompartilhadoFilterModel,
  ListaCuidadoCompartilhadoFilterPopperFormModel,
  StatusCuidadoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { EquipesCuidadoCompartilhadoSelectField } from './EquipesCuidadoCompartilhadoSelectField'
import { ProfissionalCuidadoCompartilhadoSelectField } from './ProfissionalCuidadoCompartilhadoSelectField'
import { UnidadeSaudeCuidadaoCompartilhadoSelectField } from './UnidadeSaudeCuidadoCompartilhadoSelectField'

interface ListaCuidadoCompartilhadoFilterPopperFormProps {
  isFilterDefault: boolean
  initialValues: ListaCuidadoCompartilhadoFilterPopperFormModel
  role: CuidadoCompartilhadoQueryFilterEnum
  onSubmit: (values: ListaCuidadoCompartilhadoFilterModel) => void
  onClear: () => void
  onClose: () => void
  somenteSemExecutante?: boolean
}

const path = metaPath<ListaCuidadoCompartilhadoFilterPopperFormModel>()

export const ListaCuidadoCompartilhadoFilterPopperForm = (props: ListaCuidadoCompartilhadoFilterPopperFormProps) => {
  const { isFilterDefault, initialValues, role, onSubmit, onClear, onClose, somenteSemExecutante } = props

  const { acesso } = useAcessoLotacaoOrEstagio()
  const isUbs = acesso.unidadeSaude.tipoEstabelecimento === TipoEstabelecimentoEnum.UBS
  const isGerente = role === CuidadoCompartilhadoQueryFilterEnum.GERENTE

  const handleSubmit = (values: ListaCuidadoCompartilhadoFilterModel) => {
    const hasExecutante = !isUndefinedOrNull(values.profissionalExecutante)
    const hasSocilitante = !isUndefinedOrNull(values.profissionalSolicitante)

    const newSomenteOsMeus =
      (hasSocilitante && role === CuidadoCompartilhadoQueryFilterEnum.SOLICITANTE) ||
      (hasExecutante && role === CuidadoCompartilhadoQueryFilterEnum.EXECUTANTE)
        ? false
        : values.somenteOsMeus

    onSubmit({ ...values, somenteOsMeus: newSomenteOsMeus })
    onClose()
  }

  const renderForm = (renderProps: FormRenderProps<ListaCuidadoCompartilhadoFilterPopperFormModel>) => (
    <Box style={styles.box}>
      <Grid gap={1}>
        <Cell size={12}>
          <FormControl label='Status'>
            <HFlow>
              <CheckboxField
                name={path.status}
                label={filterCheckboxLabelRender(
                  StatusCuidadoRecord[StatusCuidadoCompartilhadoEnum.AGUARDANDO].descricao,
                  styles.statusCheckboxLabel(StatusCuidadoCompartilhadoEnum.AGUARDANDO)
                )}
                value={StatusCuidadoCompartilhadoEnum.AGUARDANDO}
                style={styles.checkbox}
              />
              <CheckboxField
                name={path.status}
                label={filterCheckboxLabelRender(
                  StatusCuidadoRecord[StatusCuidadoCompartilhadoEnum.RESPONDIDO].descricao,
                  styles.statusCheckboxLabel(StatusCuidadoCompartilhadoEnum.RESPONDIDO)
                )}
                value={StatusCuidadoCompartilhadoEnum.RESPONDIDO}
                style={styles.checkbox}
              />
            </HFlow>
          </FormControl>
        </Cell>
        <Cell size={6}>
          <DateRangeField name={path.periodo} label='Período da solicitação' />
        </Cell>
        <Cell size={6}>
          {!isGerente && (
            <UnidadeSaudeCuidadaoCompartilhadoSelectField
              name={path.unidadeSaude}
              label='Unidade de saúde'
              role={role}
            />
          )}
        </Cell>
        <Cell size={6}>
          <ProfissionalCuidadoCompartilhadoSelectField
            name={path.profissionalSolicitante}
            label='Profissional solicitante'
            role={role}
            isProfissionaisSolicitantes
            somenteSemExecutante={somenteSemExecutante}
          />
        </Cell>
        <Cell size={6}>
          {isUbs && (
            <ProfissionalCuidadoCompartilhadoSelectField
              name={path.profissionalExecutante}
              label='Profissional executante'
              role={role}
              isProfissionaisSolicitantes={false}
              somenteSemExecutante={somenteSemExecutante}
            />
          )}
        </Cell>
        <Cell size={6}>
          {isGerente && <EquipesCuidadoCompartilhadoSelectField name={path.equipe} label='Equipe' multiple />}
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onClose} size='small' skin='outline' kind='normal'>
              Fechar
            </Button>

            <Tooltip text='Redefinir filtros para o padrão'>
              <Button kind='normal' size='small' onClick={onClear} disabled={isFilterDefault}>
                <Icon style={styles.redoIcon} icon='redo' />
                Voltar para padrão
              </Button>
            </Tooltip>

            <SubmitButton size='small' handleSubmit={renderProps.handleSubmit}>
              Filtrar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )

  return <Form initialValues={initialValues} render={renderForm} onSubmit={handleSubmit} />
}

const styles = {
  box: css`
    width: 42rem;
    margin-top: 1rem;
  `,
  redoIcon: css`
    padding-right: 0.5rem;
  `,
  checkbox: css`
    margin-left: -0.25rem;
  `,
  statusCheckboxLabel: (status: StatusCuidadoCompartilhadoEnum) => css`
    background: ${StatusCuidadoRecord[status].cor.c40};
  `,
}
