/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { FormState } from 'final-form'
import { CuidadoCompartilhadoQueryFilterEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoSortEnum,
  ListaCuidadoCompartilhadoFilterModel,
  ListaCuidadoCompartilhadoInlineFilterModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { ListaCuidadoCompartilhadoFilterPopperForm } from './ListaCuidadoCompartilhadoFilterPopperForm'
import { ListaCuidadoCompartilhadoFilterTags } from './ListaCuidadoCompartilhadoFilterTags'
import { ListaCuidadoCompartilhadoSortDropdown } from './ListaCuidadoCompartilhadoSortDropdown'

interface ListaCuidadoCompartilhadoFilterProps {
  value: ListaCuidadoCompartilhadoFilterModel
  itemsToSort: CuidadoCompartilhadoSortEnum[]
  filterSomenteOsMeusLabel: string
  isFilterDefault: boolean
  role: CuidadoCompartilhadoQueryFilterEnum
  onChange(value: ListaCuidadoCompartilhadoFilterModel): void
  onClear: () => void
  somenteSemExecutante?: boolean
}

const meta = metaPath<ListaCuidadoCompartilhadoInlineFilterModel>()

export const ListaCuidadoCompartilhadoFilter = (props: ListaCuidadoCompartilhadoFilterProps) => {
  const {
    value,
    itemsToSort,
    filterSomenteOsMeusLabel,
    isFilterDefault,
    role,
    onChange,
    onClear,
    somenteSemExecutante,
  } = props

  const [countAppliedFilter, setCountAppliedFilter] = useState(0)
  const { CUIDADO_COMPARTILHADO_ENABLED } = useFlags()

  const isGerente = role === CuidadoCompartilhadoQueryFilterEnum.GERENTE

  const handleDebounceChange = (formState: FormState<ListaCuidadoCompartilhadoFilterModel>) => {
    const newProfSolicitante =
      formState.values.somenteOsMeus && role === CuidadoCompartilhadoQueryFilterEnum.SOLICITANTE
        ? null
        : value.profissionalSolicitante
    const newProfExecutante =
      formState.values.somenteOsMeus && role === CuidadoCompartilhadoQueryFilterEnum.EXECUTANTE
        ? null
        : value.profissionalExecutante

    onChange({
      ...value,
      ...formState.values,
      query: formState.values.query,
      profissionalSolicitante: newProfSolicitante,
      profissionalExecutante: newProfExecutante,
    })
  }

  const renderPopper = (ctrl: PopperControls) => (
    <ListaCuidadoCompartilhadoFilterPopperForm
      isFilterDefault={isFilterDefault}
      initialValues={value}
      role={role}
      onSubmit={onChange}
      onClear={onClear}
      onClose={ctrl.close}
      somenteSemExecutante={somenteSemExecutante}
    />
  )

  useEffect(() => {
    setCountAppliedFilter(
      (value?.status?.length || 0) +
        (isUndefinedOrNull(value?.profissionalExecutante) ? 0 : 1) +
        (isUndefinedOrNull(value?.profissionalSolicitante) ? 0 : 1) +
        (isUndefinedOrNull(value?.unidadeSaude) ? 0 : 1) +
        (isEmpty(value?.equipe) ? 0 : 1) +
        (value?.periodo?.endDate && value?.periodo?.startDate ? 1 : 0)
    )
  }, [value])

  const renderInlineFilterForm = () => {
    return (
      <Fragment>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <HFlow hSpacing={0.5} justifyContent='space-between'>
          <HFlow hSpacing={0.5} style={styles.hFlow}>
            {CUIDADO_COMPARTILHADO_ENABLED && (
              <TextField name={meta.query} placeholder='Pesquise por nome, CPF, CNS do cidadão' icon='zoomOutline' />
            )}
            {!isGerente && !isUndefinedOrNull(filterSomenteOsMeusLabel) && (
              <SwitchField name={meta.somenteOsMeus} label={filterSomenteOsMeusLabel} />
            )}
          </HFlow>
          <HFlow hSpacing={0.5}>
            <ListaCuidadoCompartilhadoSortDropdown
              items={itemsToSort}
              filter={value}
              onChange={onChange}
              flagEnabled={CUIDADO_COMPARTILHADO_ENABLED}
            />
            {CUIDADO_COMPARTILHADO_ENABLED && (
              <PopperButton
                kind='primary'
                size='small'
                skin='default'
                placement='bottom-end'
                renderPopper={renderPopper}
              >
                <HFlow hSpacing={0.5}>
                  <Icon icon='adjust' />
                  {'Filtros' + (countAppliedFilter > 0 ? ` (${countAppliedFilter})` : '')}
                </HFlow>
              </PopperButton>
            )}
          </HFlow>
        </HFlow>
      </Fragment>
    )
  }

  return (
    <VFlow vSpacing={0.5}>
      <Form<ListaCuidadoCompartilhadoInlineFilterModel>
        render={renderInlineFilterForm}
        initialValues={{
          somenteOsMeus: value.somenteOsMeus,
          query: value.query,
        }}
      />
      {CUIDADO_COMPARTILHADO_ENABLED && (
        <HFlow alignItems='center' justifyContent='space-between' style={{ gridAutoColumns: 'minmax(0, auto)' }}>
          <ListaCuidadoCompartilhadoFilterTags filters={value} />

          <Tooltip text='Redefinir filtros para o padrão'>
            <Button kind='primary' skin='outline' size='small' disabled={isFilterDefault} onClick={onClear}>
              <Icon style={styles.redoIcon} icon='redo' />
              Voltar para padrão
            </Button>
          </Tooltip>
        </HFlow>
      )}
    </VFlow>
  )
}

const styles = {
  hFlow: css`
    grid-template-columns: 25rem auto;

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: 20rem auto;
    }
  `,
  redoIcon: css`
    padding-right: 0.5rem;
  `,
}
