import { Button, ButtonProps, ExternalStyles, Portal } from 'bold-ui'
import { usePopperButton } from 'hooks/usePopperButton'
import React from 'react'
import { PopperProps } from 'react-popper'

export interface PopperButtonProps extends ButtonProps {
  placement?: PopperProps<any>['placement']
  positioningStrategy?: PopperProps<any>['strategy']
  renderPopper: (controls: PopperControls) => React.ReactNode
  zIndex?: number
  onClose?(): void
  styleCallback?(isOpen: boolean): ExternalStyles
  container?: Element
  onOpen?(): void
  closeOnBackdropClick?: boolean
  focusOnOpen?: boolean
  referenceElement?: Element
}

export interface PopperControls {
  open(): void
  close(): void
  toggle(): void
}

export function PopperButton(props: PopperButtonProps) {
  const {
    placement,
    positioningStrategy,
    renderPopper,
    zIndex,
    onClose,
    onOpen,
    style,
    styleCallback,
    container,
    closeOnBackdropClick,
    focusOnOpen,
    referenceElement,
    ...buttonProps
  } = props

  const { rootRef, popperContainerRef, buttonRef, open, close, toggle, isOpen, popperContainerStyle } = usePopperButton(
    {
      referenceElement,
      placement,
      strategy: positioningStrategy,
      closeOnBackdropClick,
      focusOnOpen,
    }
  )

  return (
    <>
      <Button
        innerRef={buttonRef}
        onClick={toggle}
        {...buttonProps}
        style={styleCallback ? styleCallback(isOpen) : style}
      />

      <Portal container={container}>
        <div ref={rootRef}>
          {isOpen && (
            <div
              ref={popperContainerRef}
              className={popperContainerStyle}
              data-placement={placement}
              data-visible={isOpen}
            >
              {renderPopper({ open, close, toggle })}
            </div>
          )}
        </div>
      </Portal>
    </>
  )
}
