import { DateRange } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useCallback, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { convertDateRangeToLocalDateRange } from 'util/date/dateRange'

import { Aggregator } from '../components/aggregator/model-aggregator'
import { PivotTableProps } from '../PivotTable'
import { Dictionary } from './model-treeBuilder'
import { OperationsPaths, TreeMeta } from './model-treeBuilder'
import { TreeBuilderStatus } from './model-useTreeBuilder'
import { TreeBuilder } from './TreeBuilder'

type KeyMapping<T> = PivotTableProps<T>['keyMapping']

interface TreeBuilderHookProps<T> {
  keyMapping: KeyMapping<T>
  operations?: OperationsPaths
  data?: T[]
}

export function useTreeBuilder<T>(props: TreeBuilderHookProps<T>) {
  const { keyMapping, operations, data } = props

  const [metaState, setMetaState] = useState<TreeMeta<T>>()

  const [defaultTreeState, setDefaulTreeState] = useState<Dictionary<T>>()

  const [complementaryTreeState, setComplementaryTreeState] = useState<Dictionary<T>>()

  const [maxLeafValueState, setMaxLeafValueState] = useState<number>()

  const [dateRange, setDateRange] = useState<DateRange>()

  const [periodUnit, setPeriodUnit] = useState<string>()

  const [ciapCidFilter, setCiapCidFilter] = useState<string[]>()

  const [treeBuilder] = useState<TreeBuilder<T>>(new TreeBuilder(data, operations))

  const [treeBuilderStatus, setTreeBuilderStatus] = useState<TreeBuilderStatus>({
    isBuilding: false,
    isSearchingMeta: false,
  })

  const setIsBuilding = (isBuilding: boolean) => setTreeBuilderStatus((prevStatus) => ({ ...prevStatus, isBuilding }))

  const setIsSearchingMeta = (isSearchingMeta: boolean) =>
    setTreeBuilderStatus((prevStatus) => ({ ...prevStatus, isSearchingMeta }))

  const alert = useAlert()

  const warning = useCallback((msg: any) => alert('danger', msg.error), [alert])

  const setDateFilter = useCallback((filter: DateRange, periodUnit: string) => {
    setDateRange(filter)
    setPeriodUnit(periodUnit)
  }, [])

  const demolish = useCallback(() => {
    setComplementaryTreeState(undefined)
    setDefaulTreeState(undefined)
  }, [])

  const build = useCallback(
    function <K extends keyof T>(
      rowKeys: Array<K>,
      columnKeys: Array<K>,
      aggregator: Aggregator,
      aggregatorKey: keyof T,
      filterKeys?: Map<K, Set<string>>
    ) {
      setIsBuilding(true)
      const localDateRange = convertDateRangeToLocalDateRange(dateRange)
      if (rowKeys.length > 0 && columnKeys.length > 0) {
        treeBuilder
          .build(
            [...columnKeys, ...rowKeys],
            localDateRange,
            periodUnit,
            ciapCidFilter,
            aggregator,
            aggregatorKey,
            filterKeys
          )
          .then((data) => {
            setComplementaryTreeState(data)
            setIsBuilding(false)
          })
          .catch(warning)
      }
      treeBuilder
        .build(
          [...rowKeys, ...columnKeys],
          localDateRange,
          periodUnit,
          ciapCidFilter,
          aggregator,
          aggregatorKey,
          filterKeys
        )
        .then((data) => {
          setDefaulTreeState(data)
          setMaxLeafValueState(treeBuilder.maxLeafValue)
          setIsBuilding(false)
        })
        .catch(warning)
    },
    [dateRange, treeBuilder, periodUnit, ciapCidFilter, warning]
  )

  const meta = useCallback(
    function (period: DateRange, unit: string, ciapCid: string[] = []) {
      const localDateRange = convertDateRangeToLocalDateRange(period)
      setMetaState(undefined)
      setIsSearchingMeta(true)
      treeBuilder
        .meta(keyMapping, localDateRange, unit, ciapCid)
        .then((data) => {
          if (data) {
            setMetaState(data)
            setIsSearchingMeta(false)
          }
        })
        .catch(warning)
    },
    [treeBuilder, keyMapping, warning]
  )

  // Tratando o cenario do absenteismo que usa o GraphQL para trazer os dados
  useEffect(() => {
    if (!isUndefinedOrNull(data)) meta(dateRange, periodUnit, ciapCidFilter)
  }, [ciapCidFilter, data, dateRange, meta, periodUnit])

  return {
    metaState,
    maxLeafValue: maxLeafValueState,
    defaultTree: defaultTreeState,
    complementaryTree: complementaryTreeState,
    treeBuilderStatus,
    isMetaEmpty: metaState?.isEmpty,
    setDateFilter,
    setCiapCidFilter,
    setIsSearchingMeta,
    build,
    meta,
    demolish,
  }
}
