import { Button, Cell, Grid, HFlow, Icon, Tooltip, useStyles } from 'bold-ui'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { endOfDay, startOfDay } from 'date-fns'
import { FormState } from 'final-form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useFilterCounter } from 'hooks/useFilterCounter'
import { isEmpty } from 'lodash'
import React, { useCallback } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { convertDateRangeToInstantRange, convertInstantRangeToDateRange } from 'util/date/dateRange'
import { metaPath } from 'util/metaPath'

import { createFilterStyles } from '../listaUtils'
import {
  ListaAtendimentoFilterPopperForm,
  ListaAtendimentoFilterPopperFormModel,
} from './ListaAtendimentoFilterPopperForm'
import { ListaAtendimentoFilterTags } from './ListaAtendimentoFilterTags'
import { ListaAtendimentoSortDropdown } from './ListaAtendimentoSortDropdown'
import {
  ListaAtendimentoFilterModel,
  ListaAtendimentoFilterPopperModel,
  ListaAtendimentoInlineFilterModel,
} from './model'

export interface ListaAtendimentoFilterProps {
  filter: ListaAtendimentoFilterModel
  filterBackup: ListaAtendimentoFilterPopperModel
  filterDefault: ListaAtendimentoFilterModel
  isDefaultFilter: boolean
  onChangeFilter(values: ListaAtendimentoFilterModel): void
  onClear(): void
  updateFilterBackup(values: ListaAtendimentoFilterPopperModel): void
  deleteFilterBackup(): void
}

const path = metaPath<ListaAtendimentoInlineFilterModel>()

const mountInitialValues = (values: ListaAtendimentoFilterPopperModel): ListaAtendimentoFilterPopperFormModel => {
  const isSomenteNaoFinalizado = values.isSomenteNaoFinalizados
  const isPeriodo24h = values.isPeriodo24h

  const getDate = (date: Instant) =>
    isSomenteNaoFinalizado || isPeriodo24h || isUndefinedOrNull(date) ? null : new Date(date)

  const startDate = getDate(values.periodo.startDate)
  const endDate = getDate(values.periodo.endDate)

  return {
    ...values,
    periodo: {
      startDate,
      endDate,
    },
  }
}

export function ListaAtendimentoFilter(props: ListaAtendimentoFilterProps) {
  const {
    filter,
    filterDefault,
    filterBackup,
    isDefaultFilter,
    onChangeFilter,
    updateFilterBackup,
    deleteFilterBackup,
    onClear,
  } = props

  const { query, isSomenteMeusAtendimentos, ordem, isPeriodo24h, ...restFilter } = filter

  const { analytics } = useFirebase()

  const { classes } = useStyles(createFilterStyles)
  const initialValues = mountInitialValues(isEmpty(filterBackup) ? restFilter : filterBackup)

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_LA')
  }

  const handleDebounceChange = (formState: FormState<ListaAtendimentoInlineFilterModel>) => {
    onChangeFilter({ ...filter, ...formState.values, query: formState.values.query })
  }

  const handlePopperSubmit = useCallback(
    (values: ListaAtendimentoFilterPopperFormModel) => {
      deleteFilterBackup()

      if (!isUndefinedOrNull(values.periodo)) {
        values.periodo.endDate = values.periodo.endDate && endOfDay(values.periodo.endDate)
        values.periodo.startDate = values.periodo.startDate && startOfDay(values.periodo.startDate)
      }

      onChangeFilter({ ...filter, ...values, periodo: convertDateRangeToInstantRange(values.periodo) })
    },
    [deleteFilterBackup, filter, onChangeFilter]
  )

  const renderFilterForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <Grid alignItems='center'>
          <Cell size={5} xl={4} style={classes.cell1}>
            <TextField
              name={path.query}
              placeholder='Pesquise por nome, CPF, CNS ou data de nascimento'
              icon='zoomOutline'
            />
          </Cell>
          <Cell size={7} xl={8} style={classes.cell2}>
            <HFlow style={classes.hFlow}>
              <SwitchField
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  whiteSpace: 'nowrap',
                  justifyContent: 'start',
                  maxWidth: '16.5rem',
                }}
                name={path.isSomenteMeusAtendimentos}
                label='Ver somente os meus atendimentos'
              />
              <ListaAtendimentoSortDropdown filter={filter} onChange={onChangeFilter} />
            </HFlow>
          </Cell>
        </Grid>
      </>
    )
  }

  const renderPopper = (ctrl: PopperControls) => (
    <ListaAtendimentoFilterPopperForm
      initialValues={initialValues}
      isDefaultFilter={isDefaultFilter}
      defaultPeriodo={convertInstantRangeToDateRange(filterDefault.periodo)}
      onSubmit={handlePopperSubmit}
      onFechar={ctrl.close}
      onClear={onClear}
      onChangeFilter={updateFilterBackup}
    />
  )

  const countFiltrosAplicados = useFilterCounter(restFilter)

  return (
    <Grid gap={0.5} gapVertical={0.5}>
      <Cell size={12}>
        <HFlow style={classes.hFlow}>
          <Form<ListaAtendimentoInlineFilterModel>
            onSubmit={onChangeFilter}
            render={renderFilterForm}
            initialValues={{ query, isSomenteMeusAtendimentos }}
          />
          <PopperButton
            kind='primary'
            size='small'
            skin='default'
            placement='bottom-end'
            renderPopper={renderPopper}
            onClose={deleteFilterBackup}
          >
            <Icon icon='filterOutline' />
            {'Filtros' + (countFiltrosAplicados > 0 ? ` (${countFiltrosAplicados})` : '')}
          </PopperButton>
        </HFlow>
      </Cell>

      <Cell size={12}>
        <HFlow alignItems='center' justifyContent='space-between' style={{ gridAutoColumns: 'minmax(0, auto)' }}>
          <ListaAtendimentoFilterTags filtros={filter} />

          <Tooltip text='Redefinir filtros para o padrão'>
            <Button kind='primary' skin='outline' size='small' disabled={isDefaultFilter} onClick={handleOnClear}>
              <Icon style={{ paddingRight: '0.5rem' }} icon='redo' />
              Voltar para padrão
            </Button>
          </Tooltip>
        </HFlow>
      </Cell>
    </Grid>
  )
}
