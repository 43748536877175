import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Form, FormProps } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useMemo } from 'react'
import { MetaRoot } from 'util/metaPath'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'
import { cuidadoCompartilhadoFormCalculator } from './calculator-cuidadoCompartilhadoForm'
import { cuidadoCompartilhadoFormValidator } from './validator-cuidadoCompartilhadoForm'

interface CuidadoCompartilhadoFormProps
  extends Omit<FormProps<CuidadoCompartilhadoPlanoModel>, 'validate' | 'decorators'> {
  isAtendimentoOdonto: boolean
  path: MetaRoot<CuidadoCompartilhadoPlanoModel>
  encaminhamentos: readonly CuidadoCompartilhadoPlanoModel[]
  isEdit?: boolean
}

export const CuidadoCompartilhadoForm = (props: CuidadoCompartilhadoFormProps) => {
  const { path, encaminhamentos, isAtendimentoOdonto, isEdit = false, onSubmit, ...rest } = props

  const {
    permissoes: { somenteCiap },
  } = useAtendimentoContext()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()

  const handleSubmit = (values: CuidadoCompartilhadoPlanoModel, formApi: FormApi<CuidadoCompartilhadoPlanoModel>) => {
    onSubmit(values, formApi)
    setTimeout(formApi.reset)
  }

  const decorator = useMemo(
    () =>
      createDecorator(
        ...cuidadoCompartilhadoFormCalculator(
          path,
          PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && !isAtendimentoOdonto,
          somenteCiap,
          isEdit
        )
      ),
    [PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED, isAtendimentoOdonto, isEdit, path, somenteCiap]
  )

  return (
    <Form<CuidadoCompartilhadoPlanoModel>
      validate={cuidadoCompartilhadoFormValidator(
        somenteCiap,
        acesso.unidadeSaude.id,
        PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED,
        encaminhamentos,
        isAtendimentoOdonto
      )}
      decorators={[decorator]}
      onSubmit={handleSubmit}
      {...rest}
    />
  )
}
