import { ExecutionResult } from 'graphql'
import { useAsyncProcessingStatusQuery, useRemoverProcessoAssincronoMutation } from 'graphql/hooks.generated'
import { AsyncProcessingNameEnum, RemoverProcessoAssincronoMutation } from 'graphql/types.generated'
import { useEffect } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import {
  AsyncProcessingAtmosphereResponse,
  asyncProcessingConfigurationRecord,
  AsyncProcessingMessageTypeEnum,
} from './model-asyncProcessing'

interface UseAsyncProcessingProps {
  asyncProcessingName: AsyncProcessingNameEnum
  onProcessingFinished: (messageType: AsyncProcessingMessageTypeEnum, message: string) => void
}

interface UseAsyncProcessingResult {
  isStarted: boolean
  isFinished: boolean
  hasError: boolean
  removerProcessoAssincrono: () => Promise<ExecutionResult<RemoverProcessoAssincronoMutation>>
}

export function useAsyncProcessing(props: UseAsyncProcessingProps): UseAsyncProcessingResult {
  const { asyncProcessingName, onProcessingFinished } = props
  const { finishProcessingEventName } = asyncProcessingConfigurationRecord[asyncProcessingName]

  const { data, refetch } = useAsyncProcessingStatusQuery({
    variables: { asyncProcessingName },
  })

  const [removerProcessoAssincrono] = useRemoverProcessoAssincronoMutation({
    variables: { asyncProcessingName },
    onCompleted: () => refetch,
  })

  const asyncProcessingStatus = data?.asyncProcessingStatus

  // Tratando o cenario do usuario acessar o modulo antes de ter finalizado e permanecer na pagina do modulo
  useEffect(() => {
    const handler = (event: CustomEvent<AsyncProcessingAtmosphereResponse>) => {
      removerProcessoAssincrono()
      onProcessingFinished(event.detail.type, event.detail.message)
    }
    document.addEventListener(finishProcessingEventName, handler)

    return () => {
      document.removeEventListener(finishProcessingEventName, handler)
    }
  }, [finishProcessingEventName, onProcessingFinished, removerProcessoAssincrono])

  return {
    isStarted: !!asyncProcessingStatus?.isStarted,
    isFinished: !!asyncProcessingStatus?.isFinished,
    hasError: !isUndefinedOrNull(asyncProcessingStatus?.errorMessage),
    removerProcessoAssincrono,
  }
}
