import { DateRange } from 'bold-ui'
import { has } from 'lodash'
import { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

export function useFilterCounter(filter: object) {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const values = Object.values(filter)
    let internalCounter = 0
    values.forEach((value) => {
      if (Array.isArray(value)) {
        internalCounter += value.length
      } else if (isDateRange(value)) {
        if (!isUndefinedOrNull(value.startDate) || !isUndefinedOrNull(value.endDate)) {
          internalCounter++
        }
      } else if (!!value || value === 0) {
        internalCounter++
      }
    })
    setCounter(internalCounter)
  }, [filter])

  return counter
}

function isDateRange(range: any): range is DateRange {
  if (has(range, 'startDate') && has(range, 'endDate'))
    return (
      (isUndefinedOrNull(range.startDate) || typeof range.startDate === 'object') &&
      (isUndefinedOrNull(range.endDate) || typeof range.endDate === 'object')
    )
  else return false
}
