/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { catmatTalidomida } from 'api/ImpressaoMedicamentos'
import { Checkbox, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { resolveName, usePecField } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { tipoReceitaRecord } from 'types/enums'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { resolveMedicamento } from '../../utils/converter-prescricao'
import { hasIsotretinoinaTretinoinaAcitretina, isOnPrescricaoDigitalAtiva } from '../../utils/utils-prescricao'
import { PrescricaoDigitalFormModel } from '../prescricao-digital/model-prescricaoDigital'
import { MedicamentoImpressoInfoLabel } from './MedicamentoImpressoInfoLabel'
import { MedicamentoOnPrescricaoDigitalInfoLabel } from './MedicamentoOnPrescricaoDigitalInfoLabel'
import { PrescricaoBoxItem } from './PrescricaoBoxItem'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

interface EncaminharPrescricaoMedicamentoItemProps {
  prescricaoDigital: PrescricaoDigitalFormModel
  medicamentosToPrint: MedicamentoFormModel[]
  medicamentoIdsSelecionado: ID[]
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  disableReceitaControlada?: boolean
  isPrescricaoDigital?: boolean
  tipoReceitaSelecionado?: TipoReceitaEnum
}

export function EncaminharPrescricaoMedicamentoItem(props: EncaminharPrescricaoMedicamentoItemProps) {
  const {
    prescricaoDigital,
    medicamentosToPrint,
    medicamentoIdsSelecionado,
    disableReceitaControlada = false,
    onChange,
    isPrescricaoDigital,
    tipoReceitaSelecionado,
  } = props

  const {
    input: { value: alreadyPrintedValue },
  } = usePecField({ name: resolveName(path.alreadyPrinted) })

  const isPrinted = useCallback((medicamentoId: ID) => !!alreadyPrintedValue?.includes(medicamentoId), [
    alreadyPrintedValue,
  ])

  const isSelecionado = useCallback((medicamentoId: ID) => medicamentoIdsSelecionado.includes(medicamentoId), [
    medicamentoIdsSelecionado,
  ])

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {medicamentosToPrint.map((medicamento, index) => (
        <MedicamentoItem
          index={index}
          medicamento={medicamento}
          prescricaoDigital={prescricaoDigital}
          isPrescricaoDigital={isPrescricaoDigital}
          disableReceitaControlada={disableReceitaControlada}
          isPrinted={isPrinted}
          onChange={onChange}
          checked={isSelecionado}
          tipoReceitaSelecionado={tipoReceitaSelecionado}
          key={medicamento.id}
        />
      ))}
    </VFlow>
  )
}

interface MedicamentoItemProps {
  medicamento: MedicamentoFormModel
  prescricaoDigital: PrescricaoDigitalFormModel
  index: number
  isPrescricaoDigital: boolean
  disableReceitaControlada: boolean
  isPrinted(medicamentoId: ID): boolean
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  checked(medicamentoId: ID): boolean
  tipoReceitaSelecionado: TipoReceitaEnum
}

const MedicamentoItem = (props: MedicamentoItemProps) => {
  const {
    medicamento,
    prescricaoDigital,
    index,
    isPrescricaoDigital,
    disableReceitaControlada,
    isPrinted,
    onChange,
    checked,
    tipoReceitaSelecionado,
  } = props

  const { principioAtivo, concentracao, tipoReceita } = resolveMedicamento(medicamento)

  const { PRESCRICAO_CONTROLADA } = useFlags()

  const isMedicamentoAlreadyOnPrescricaoDigital =
    isPrescricaoDigital && isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento)

  const isDisabledMedicamentoReceitaControlada =
    !PRESCRICAO_CONTROLADA && disableReceitaControlada && tipoReceita !== TipoReceitaEnum.COMUM

  const isDisabledByTipoReceitaSelecionado = tipoReceitaSelecionado && tipoReceita !== tipoReceitaSelecionado

  const isMedicamentoDisabled =
    isDisabledMedicamentoReceitaControlada ||
    isMedicamentoAlreadyOnPrescricaoDigital ||
    isDisabledByTipoReceitaSelecionado

  const isTalidomida = medicamento.principioAtivoCombo?.codigoCatMat === catmatTalidomida

  const hasTags = medicamento.usoContinuo || tipoReceita !== TipoReceitaEnum.COMUM

  const tooltipText = isDisabledMedicamentoReceitaControlada
    ? 'Não é possível incluir medicamentos de uso controlado na prescrição digital'
    : isMedicamentoAlreadyOnPrescricaoDigital
    ? 'Medicamento já incluído em uma prescrição digital'
    : isDisabledByTipoReceitaSelecionado
    ? 'Não é possível selecionar medicamentos com tipos de receita diferentes'
    : null

  return (
    <PrescricaoBoxItem key={medicamento.id} data-cy={`Medicamento${index}`}>
      <HFlow justifyContent='space-between'>
        <HFlow>
          <Tooltip text={tooltipText}>
            <span
              css={css`
                margin: auto;
              `}
            >
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, medicamento)}
                onKeyDown={(e) => e.stopPropagation()}
                checked={!isMedicamentoDisabled && checked(medicamento.id)}
                disabled={isMedicamentoDisabled}
                data-cy={`Medicamento${index}Checkbox`}
              />
            </span>
          </Tooltip>

          <VFlow vSpacing={0.25}>
            <Text fontWeight='bold' data-cy={`PrincipioAtivo${index}`}>{`${principioAtivo} ${concentracao}`}</Text>
            {hasTags && (
              <HFlow>
                {medicamento.usoContinuo && (
                  <Tag
                    type='normal'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    Uso contínuo
                  </Tag>
                )}
                {tipoReceita !== TipoReceitaEnum.COMUM && (
                  <Tag
                    type='info'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    {tipoReceitaRecord[tipoReceita]}
                  </Tag>
                )}
              </HFlow>
            )}
            {(isTalidomida || hasIsotretinoinaTretinoinaAcitretina([medicamento])) && (
              <Tag
                type='normal'
                style={css`
                  align-self: center;
                  max-height: 1.5rem;
                `}
              >
                Medicamento com termo de uso e consentimento
              </Tag>
            )}
            <Text data-cy={`Posologia${index}`}>{medicamento.posologia}</Text>
          </VFlow>
        </HFlow>
        <VFlow
          vSpacing={0.25}
          style={css`
            align-items: end;
          `}
        >
          {isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento) && <MedicamentoOnPrescricaoDigitalInfoLabel />}
          {isPrinted(medicamento.id) && <MedicamentoImpressoInfoLabel />}
        </VFlow>
      </HFlow>
    </PrescricaoBoxItem>
  )
}
