import { useFlags } from 'config/useFlagsContext'

export type NewsVersion = '5-2-estabilizacao' | '5-2' | '5-1-estabilizacao' | '5-1' | '5-0-estabilizacao' | '5-0'

export type NewsItem = {
  title: string
  version: NewsVersion
  hashChildren: ReadonlyArray<string>
  children: ReadonlyArray<{ title: string; to: string }>
}

export default function useNewsItems() {
  const {
    VISUALIZACAO_TERRITORIO_ENABLED,
    CUIDADO_COMPARTILHADO_ENABLED,
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED,
    PILOTO_VIDEOCHAMADA_2_ENABLED,
    PILOTO_VIDEOCHAMADA_3_ENABLED,
    PILOTO_ATESTADO_DIGITAL_ENABLED,
  } = useFlags()

  const NEWS_ITEMS: NewsItem[] = [
    {
      title: 'Estabilização 5.2',
      version: '5-2-estabilizacao',
      hashChildren: [
        '#estabilizacao-5-2-37',
        '#estabilizacao-5-2-36',
        '#estabilizacao-5-2-35',
        '#estabilizacao-5-2-33',
        '#estabilizacao-5-2-32',
        '#estabilizacao-5-2-31',
        '#estabilizacao-5-2-30',
        '#estabilizacao-5-2-29',
        '#estabilizacao-5-2-28',
        '#estabilizacao-5-2-27',
        '#estabilizacao-5-2-26',
        '#estabilizacao-5-2-24',
        '#estabilizacao-5-2-23',
        '#estabilizacao-5-2-22',
        '#estabilizacao-5-2-21',
        '#estabilizacao-5-2-20',
      ],
      children: [
        {
          title: '5.2.37',
          to: '#estabilizacao-5-2-37',
        },
        {
          title: '5.2.36',
          to: '#estabilizacao-5-2-36',
        },
        {
          title: '5.2.35',
          to: '#estabilizacao-5-2-35',
        },
        {
          title: '5.2.33',
          to: '#estabilizacao-5-2-33',
        },
        {
          title: '5.2.32',
          to: '#estabilizacao-5-2-32',
        },
        {
          title: '5.2.31',
          to: '#estabilizacao-5-2-31',
        },
        {
          title: '5.2.30',
          to: '#estabilizacao-5-2-30',
        },
        {
          title: '5.2.29',
          to: '#estabilizacao-5-2-29',
        },
        {
          title: '5.2.28',
          to: '#estabilizacao-5-2-28',
        },
        {
          title: '5.2.27',
          to: '#estabilizacao-5-2-27',
        },
        {
          title: '5.2.26',
          to: '#estabilizacao-5-2-26',
        },
        {
          title: '5.2.24',
          to: '#estabilizacao-5-2-24',
        },
        {
          title: '5.2.23',
          to: '#estabilizacao-5-2-23',
        },
        {
          title: '5.2.22',
          to: '#estabilizacao-5-2-22',
        },
        {
          title: '5.2.21',
          to: '#estabilizacao-5-2-21',
        },
        {
          title: '5.2.20',
          to: '#estabilizacao-5-2-20',
        },
      ],
    },
    {
      title: 'Versão 5.2',
      version: '5-2',
      hashChildren: [
        '#atualizacao-competencia-sigtap-5-2',
        '#modelos-personalizados-relatorios-gerenciais-5-2',
        ...(PILOTO_VIDEOCHAMADA_2_ENABLED && PILOTO_VIDEOCHAMADA_3_ENABLED
          ? ['#agendamento-entre-profissionais-5-2']
          : []),
        '#agendamento-pre-natal-5-2',
        '#busca-ativa-vacinacao-5-2',
        '#garantia-acesso-5-2',
        '#prescricao-digital-5-2',
        '#videochamadas-5-2',
        '#equipes-vinculadas-5-2',
        ...(CUIDADO_COMPARTILHADO_ENABLED ? ['#cuidado-compartilhado-5-2'] : []),
        ...(HISTORICO_CUIDADO_COMPARTILHADO_ENABLED ? ['#historico-cuidado-compartilhado-5-2'] : []),
        '#envio-rnds-5-2',
        ...['#esusAmpliado-5-2'],
        ...(VISUALIZACAO_TERRITORIO_ENABLED ? ['#acompanhamento-territorio-5-2'] : []),
        '#prescricao-medicamentos-5-2',
        '#unificacao-de-cadastros-5-2',
        '#gerenciamento-de-cookies-5-2',
        ...(PILOTO_ATESTADO_DIGITAL_ENABLED ? ['#atestado-digital-5-2'] : []),
      ],
      children: [
        { title: 'Atualização da competência do SIGTAP', to: '#atualizacao-competencia-sigtap-5-2' },
        ...(PILOTO_VIDEOCHAMADA_2_ENABLED && PILOTO_VIDEOCHAMADA_3_ENABLED
          ? [
              {
                title: 'Agendamento entre profissionais',
                to: '#agendamento-entre-profissionais-5-2',
              },
            ]
          : []),
        ...(VISUALIZACAO_TERRITORIO_ENABLED
          ? [
              {
                title: 'Acompanhamento do Território',
                to: '#acompanhamento-territorio-5-2',
              },
            ]
          : []),
        ...(PILOTO_ATESTADO_DIGITAL_ENABLED
          ? [
              {
                title: 'Atestado Digital',
                to: '#atestado-digital-5-2',
              },
            ]
          : []),
        { title: 'Busca ativa de vacinação', to: '#busca-ativa-vacinacao-5-2' },
        ...(CUIDADO_COMPARTILHADO_ENABLED
          ? [
              {
                title: 'Cuidado Compartilhado',
                to: '#cuidado-compartilhado-5-2',
              },
            ]
          : []),
        ...[
          {
            title: 'e-SUS APS Ampliado',
            to: '#esusAmpliado-5-2',
          },
        ],
        { title: 'Facilidades no agendamento de consultas de pré-natal', to: '#agendamento-pre-natal-5-2' },
        { title: 'Gerenciamento de cookies', to: '#gerenciamento-de-cookies-5-2' },
        { title: 'Garantia do acesso', to: '#garantia-acesso-5-2' },
        ...(HISTORICO_CUIDADO_COMPARTILHADO_ENABLED
          ? [
              {
                title: 'Histórico do Cuidado Compartilhado',
                to: '#historico-cuidado-compartilhado-5-2',
              },
            ]
          : []),
        {
          title: 'Modelos personalizados para relatórios gerenciais',
          to: '#modelos-personalizados-relatorios-gerenciais-5-2',
        },
        { title: 'Prescrição de medicamentos', to: '#prescricao-medicamentos-5-2' },
        { title: 'Prescrição digital', to: '#prescricao-digital-5-2' },
        {
          title: 'Relatório gerencial de cuidado compartilhado',
          to: '#relatorio-gerencial-cuidado-compartilhado-5-2',
        },
        { title: 'RNDS - Envio de registros', to: '#envio-rnds-5-2' },
        { title: 'Unificação de cadastros', to: '#unificacao-de-cadastros-5-2' },
        { title: 'Videochamadas', to: '#videochamadas-5-2' },
        { title: 'Vínculo entre equipes do PEC', to: '#equipes-vinculadas-5-2' },
      ],
    },
    {
      title: 'Estabilização 5.1',
      version: '5-1-estabilizacao',
      hashChildren: [
        '#estabilizacao-5-1-17',
        '#estabilizacao-5-1-18',
        '#estabilizacao-5-1-19',
        '#estabilizacao-5-1-20',
        '#estabilizacao-5-1-21',
        '#estabilizacao-5-1-24',
        '#estabilizacao-5-1-25',
        '#estabilizacao-5-1-26',
      ],
      children: [
        {
          title: '5.1.26',
          to: '#estabilizacao-5-1-26',
        },
        {
          title: '5.1.25',
          to: '#estabilizacao-5-1-25',
        },
        {
          title: '5.1.24',
          to: '#estabilizacao-5-1-24',
        },
        {
          title: '5.1.21',
          to: '#estabilizacao-5-1-21',
        },
        {
          title: '5.1.20',
          to: '#estabilizacao-5-1-20',
        },
        {
          title: '5.1.19',
          to: '#estabilizacao-5-1-19',
        },
        {
          title: '5.1.18',
          to: '#estabilizacao-5-1-18',
        },
        {
          title: '5.1.17',
          to: '#estabilizacao-5-1-17',
        },
      ],
    },
    {
      title: 'Versão 5.1',
      version: '5-1',
      hashChildren: [
        '#mca-5-1',
        '#encaminhamento-5-1',
        '#estagiario-5-1',
        '#observacao-5-1',
        '#busca-ativa-vacinacao-5-1',
        '#registro-tardio-5-1',
        '#acompanhamento-cidadaos-vinculados-5-1',
        '#cadsus-sinc-5-1',
      ],
      children: [
        { title: 'Marcadores de consumo alimentar', to: '#mca-5-1' },
        { title: 'Busca ativa de vacinação', to: '#busca-ativa-vacinacao-5-1' },
        {
          title: 'Encaminhamento externo',
          to: '#encaminhamento-5-1',
        },
        { title: 'Adaptação do PEC para uso do estagiário', to: '#estagiario-5-1' },
        { title: 'Reterritorialização', to: '#reterritorializacao-5-1' },
        { title: 'Observação do cidadão', to: '#observacao-5-1' },
        { title: 'Acompanhamento de cidadãos vinculados', to: '#acompanhamento-cidadaos-vinculados-5-1' },
        { title: 'Registro tardio', to: '#registro-tardio-5-1' },
        { title: 'CADSUS - Nova Sincronização', to: '#cadsus-sinc-5-1' },
      ],
    },
    {
      title: 'Estabilização 5.0',
      version: '5-0-estabilizacao',
      hashChildren: [
        '#estabilizacao-5-0-24',
        '#estabilizacao-5-0-23',
        '#estabilizacao-5-0-22',
        '#estabilizacao-5-0-21',
        '#estabilizacao-5-0-20',
        '#estabilizacao-5-0-19',
        '#estabilizacao-5-0-18',
        '#estabilizacao-5-0-17',
        '#estabilizacao-5-0-16',
        '#estabilizacao-5-0-15',
        '#estabilizacao-5-0-14',
        '#estabilizacao-5-0-13',
        '#estabilizacao-5-0-12',
        '#estabilizacao-5-0-11',
        '#estabilizacao-5-0-10',
        '#estabilizacao-5-0-9',
        '#estabilizacao-5-0-8',
      ],
      children: [
        {
          title: '5.0.24',
          to: '#estabilizacao-5-0-24',
        },
        {
          title: '5.0.23',
          to: '#estabilizacao-5-0-23',
        },
        {
          title: '5.0.22',
          to: '#estabilizacao-5-0-22',
        },
        {
          title: '5.0.21',
          to: '#estabilizacao-5-0-21',
        },
        {
          title: '5.0.20',
          to: '#estabilizacao-5-0-20',
        },
        {
          title: '5.0.19',
          to: '#estabilizacao-5-0-19',
        },
        {
          title: '5.0.18',
          to: '#estabilizacao-5-0-18',
        },
        {
          title: '5.0.17',
          to: '#estabilizacao-5-0-17',
        },
        {
          title: '5.0.16',
          to: '#estabilizacao-5-0-16',
        },
        {
          title: '5.0.15',
          to: '#estabilizacao-5-0-15',
        },
        {
          title: '5.0.14',
          to: '#estabilizacao-5-0-14',
        },
        {
          title: '5.0.13',
          to: '#estabilizacao-5-0-13',
        },
        {
          title: '5.0.12',
          to: '#estabilizacao-5-0-12',
        },
        {
          title: '5.0.11',
          to: '#estabilizacao-5-0-11',
        },
        {
          title: '5.0.10',
          to: '#estabilizacao-5-0-10',
        },
        {
          title: '5.0.9',
          to: '#estabilizacao-5-0-9',
        },
        {
          title: '5.0.8',
          to: '#estabilizacao-5-0-8',
        },
      ],
    },
    {
      title: 'Versão 5.0',
      version: '5-0',
      hashChildren: [
        '#alergias-5-0',
        '#atendimentos-5-0',
        '#atendimento-odonto-5-0',
        '#atestados-5-0',
        '#encaminhamento-orientacoes-5-0',
        '#escuta-inicial-5-0',
        '#exames-5-0',
        '#historico-5-0',
        '#medicamentos-5-0',
        '#pre-natal-5-0',
        '#prontuario-5-0',
        '#antecedentes-5-0',
        '#vacinacao-5-0',
        '#acompanhamentos-5-0',
      ],
      children: [
        {
          title: 'Acompanhamentos',
          to: '#acompanhamentos-5-0',
        },
        {
          title: 'Alergias',
          to: '#alergias-5-0',
        },
        {
          title: 'Antecedentes',
          to: '#antecedentes-5-0',
        },
        {
          title: 'Atendimentos',
          to: '#atendimentos-5-0',
        },
        {
          title: 'Atendimento odontológico',
          to: '#atendimento-odonto-5-0',
        },
        {
          title: 'Atestados',
          to: '#atestados-5-0',
        },
        {
          title: 'Encaminhamentos e orientações',
          to: '#encaminhamento-orientacoes-5-0',
        },
        {
          title: 'Escuta inicial / pré-atendimento',
          to: '#escuta-inicial-5-0',
        },
        {
          title: 'Exames',
          to: '#exames-5-0',
        },
        {
          title: 'Histórico clínico do cidadão',
          to: '#historico-5-0',
        },
        {
          title: 'Medicamentos',
          to: '#medicamentos-5-0',
        },
        {
          title: 'Pré-natal / puerpério',
          to: '#pre-natal-5-0',
        },
        {
          title: 'Prontuário / folha de rosto',
          to: '#prontuario-5-0',
        },
        {
          title: 'Vacinação',
          to: '#vacinacao-5-0',
        },
      ],
    },
  ]

  return NEWS_ITEMS
}
